import { Subscription } from 'rxjs';

/** A container to handle (multiple) subscriptions. */
export class SubscriptionsContainer {

  private subscriptions: Subscription[] = [];

  /** Adds a subscription to the subscription container. */
  set add(sub: Subscription) {
    this.subscriptions.push(sub);
  }

  /**Unsubscribes and clears all subscriptions inside the subscription container. */
  dispose() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions.splice(0);
  }
}
