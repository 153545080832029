import { Component, OnInit } from '@angular/core';
import {WorkflowActionHistoryComponent} from "@dsvs/workflow-generator-ui-lib";

@Component({
  selector: 'fes-fes-workflow-action-weitere-referenz-add-history',
  templateUrl: './fes-workflow-action-weitere-referenz-add-history.component.html',
  styleUrls: ['./fes-workflow-action-weitere-referenz-add-history.component.scss']
})
export class FesWorkflowActionWeitereReferenzAddHistoryComponent extends WorkflowActionHistoryComponent implements OnInit {


}
