import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { ProductRelationItem, RoleRelationPage, RoleUserRelationPage } from '@dsvs/workflow-generator-ui-lib';
import { Process } from '../interfaces/dtos/process';
import { DevVersionRelationPage } from './devversion.hal.item';
import { FiReleaseDateRelationItem } from './fiReleaseDateHalItem';
import { MainRoleUserRelationPage } from './mainroleuser.hal.item';
import { ManuscriptRelationPage } from './manuscript.hal.item';
import { ProcessStepStateRelationItem } from './processstepstate.hal.item';
import { ProjectRelationPage } from './project.hal.item';
import { RequirementRelationPage } from './requirement.hal.item';
import { ServiceupdateRelationItem } from './serviceupdate.hal.item';
import { UserRelationItem, UserRelationPage } from './user.hal.item';
import { VersionRelationItem } from './version.hal.item';
import { WGContextRelationItem } from './wfg/wgcontext.hal.item';

export class ProcessHalItem extends HalItemImpl<Process> {
  jurist: any;
  //wreferenz: string;
  constructor(data: Process, client: HalClient, factory: HalClientFactory) {
    super(data, client, factory);

    this.context = new WGContextRelationItem('context', this);
    this.baseversion = new VersionRelationItem('baseversion', this);
    this.resultversion = new VersionRelationItem('resultversion', this);
    this.product = new ProductRelationItem('product', this);
    this.serviceupdate = new ServiceupdateRelationItem('serviceupdate', this);
    this.fireleasedate = new FiReleaseDateRelationItem('fireleasedate', this);

    this.mainroleuser = new MainRoleUserRelationPage('mainroleusers', this);
    this.activeroles = new RoleRelationPage('roles', this);
    this.roleusers = new RoleUserRelationPage('roleusers', this);
    this.project = new ProjectRelationPage('project', this);
    this.devversions = new DevVersionRelationPage('devversions', this);
    this.requirements = new RequirementRelationPage('requirements', this);
    this.users = new UserRelationPage('users', this);
    this.manuscripts = new ManuscriptRelationPage('manuscripts', this);

    this.activemainuser = new UserRelationItem('activemainuser', this);
    this.stepstate = new ProcessStepStateRelationItem('stepstate', this);
  }

  readonly context: WGContextRelationItem;
  readonly baseversion: VersionRelationItem;
  readonly resultversion: VersionRelationItem;
  readonly product: ProductRelationItem;
  readonly serviceupdate: ServiceupdateRelationItem;
  readonly fireleasedate: FiReleaseDateRelationItem;

  readonly mainroleuser: MainRoleUserRelationPage;
  readonly activeroles: RoleRelationPage;
  readonly roleusers: RoleUserRelationPage;
  readonly project: ProjectRelationPage;
  readonly devversions: DevVersionRelationPage;
  readonly requirements: RequirementRelationPage;
  readonly users: UserRelationPage;
  readonly manuscripts: ManuscriptRelationPage;

  readonly activemainuser: UserRelationItem;

  readonly stepstate: ProcessStepStateRelationItem;

}

export class ProcessHalPage extends HalPageImpl<Process, ProcessHalItem> {

}

export class ProcessRelationPage extends HalRelationPage<Process, ProcessHalItem, ProcessHalPage> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, ProcessHalPage, ProcessHalItem);
  }
}

export class ProcessRelationItem extends HalRelationItem<Process, ProcessHalItem> {
  constructor(relation: string,
    item: HalItem<any>) {
    super(relation, item, ProcessHalItem);
  }
}
