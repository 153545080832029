import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {DsvsHalAsyncLoaderModule, DsvsInlineEditModule, DsvsSharedModule, DsvsTableModule} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowGeneratorModule} from '@dsvs/workflow-generator-ui-lib';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {PanelModule} from 'primeng/panel';
import {SplitButtonModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import { LanguageDetailComponent } from '../language/components/language/language-detail/language-detail.component';
import { LanguageListComponent } from '../language/components/language/list/language-list.component';
import {SharedModule} from '../shared/shared.module';
import {GlobalCrewsComponent} from './components/globalcrews/global-crews.component';
import {GlobalCrewsListComponent} from './components/globalcrews/list/global-crews-list.component';
import {ProjectsCreateWithFormsComponent} from './components/projects/create-with-forms/projects-create-with-forms.component';
import {ProjectsDetailComponent} from './components/projects/detail/projects-detail.component';
import {ProjectsListComponent} from './components/projects/list/projects-list.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {TagsDetailComponent} from './components/tags/create/tags-detail.component';
import {TagsListComponent} from './components/tags/list/tags-list.component';
import {TagsComponent} from './components/tags/tags.component';
import {UsersListCrewsComponent} from './components/users/list/users-list-crews/users-list-crews.component';
import {UsersListComponent} from './components/users/list/users-list.component';
import {UsersComponent} from './components/users/users.component';
import {WorkspacesDetailComponent} from './components/workspaces/create/workspaces-detail.component';
import {WorkspacesListComponent} from './components/workspaces/list/workspaces-list.component';
import {WorkspacesComponent} from './components/workspaces/workspaces.component';
import {FormularListComponent} from '../formular/components/formular/list/formular-list.component';
import {FormularSearchScreenComponent} from '../formular/components/formular/search-screen/formular-search-screen.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    PanelModule,
    DialogModule,
    ButtonModule,
    TableModule,
    SplitButtonModule,
    DsvsSharedModule,
    WorkflowGeneratorModule,
    DsvsTableModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule
  ],
  declarations: [
    WorkspacesListComponent,
    WorkspacesDetailComponent,
    WorkspacesComponent,
    TagsListComponent,
    TagsDetailComponent,
    TagsComponent,
    ProjectsListComponent,
    ProjectsDetailComponent,
    ProjectsComponent,
    ProjectsCreateWithFormsComponent,
    UsersComponent,
    UsersListComponent,
    UsersListCrewsComponent,
    GlobalCrewsComponent,
    GlobalCrewsListComponent
  ],
  exports: [
    WorkspacesListComponent,
    WorkspacesDetailComponent,
    WorkspacesComponent,
    TagsListComponent,
    TagsDetailComponent,
    TagsComponent,
    ProjectsListComponent,
    ProjectsDetailComponent,
    ProjectsComponent,
    ProjectsCreateWithFormsComponent,
    UsersComponent,
    UsersListComponent,
    GlobalCrewsComponent,
    GlobalCrewsListComponent
  ]
})
export class AdministrationModule {

  static getRoutes(): Routes {
    return [
      {
        path: 'workspaces', component: WorkspacesComponent, children: [
          {path: '', component: WorkspacesListComponent}
        ]
      },
      {
        path: 'tags', component: TagsComponent, children: [
          {path: '', component: TagsListComponent}
        ]
      },
      {
        path: 'projects', component: ProjectsComponent, children: [
          {path: '', component: ProjectsListComponent}
        ]
      },
      {
        path: 'users', component: UsersComponent, children: [
          {path: '', component: UsersListComponent}
        ]
      },
      {
        path: 'globalcrews', component: GlobalCrewsComponent, children: [
          {path: '', component: GlobalCrewsListComponent}
        ]
      },
      {path: 'forms', component: FormularListComponent},
      {path: 'forms/:id', component: FormularSearchScreenComponent},
      {path: 'languages', component: LanguageListComponent},
      {path: 'languages/:id', component: LanguageDetailComponent},

    ];
  }
}

