import { Location } from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HalItem } from '@dsvs/hal-client';
import {Observable, Subscription} from 'rxjs';
import { LanguageHalItem } from '../../../../shared/hal/language.hal.item';
import { Language } from '../../../../shared/interfaces/dtos/language';
import { LanguageServiceImpl } from '../../../../shared/services/language.service';

@Component({
  selector: 'fes-language-detail',
  templateUrl: './language-detail.component.html',
  styleUrls: ['./language-detail.component.scss']
})
export class LanguageDetailComponent implements OnInit, OnDestroy {
  selectedLanguageId: string = null;
  loading = false;
  language: LanguageHalItem;
  activeMode = [
    {label: 'Nein', value: false},
    {label: 'Ja', value: true}
  ];
  languageByIdSubscription: Subscription;
  isEditable: boolean = false;
  @Input() isNew = false;


  constructor(
    private location: Location,
    private route: ActivatedRoute,
    public languageService: LanguageServiceImpl,
  ) {
    this.selectedLanguageId = this.route.snapshot.paramMap.get('id');
    this.getLanguageById(this.selectedLanguageId);
  }

  ngOnInit() {
    this.selectedLanguageId = this.route.snapshot.paramMap.get('id');
    this.getLanguageById(this.selectedLanguageId);
  }

  getLanguageById(id: string) {
    this.loading = true;
    this.languageByIdSubscription = this.languageService.getById(id).subscribe(
      (language: LanguageHalItem) => {
        this.loading = false;
        this.language = language;
      }
    );
  }

  selectActiveMode(event: { value: any; }) {
    this.language.data.active = event.value;
    this.languageService.update(this.language.data).subscribe(() => {
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.languageByIdSubscription.unsubscribe();
  }

  saveData = (value: any, component: any) => {
    //Set active to false, if displayname ist empty
    if(this.language.data.displayName === "")
      this.language.data.active = false;

    return this.language.save(this.language.data);
  }

}
