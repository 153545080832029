import { Component, OnInit } from '@angular/core';
import { DsvsBreadcrumbService } from '@dsvs/dsvs-shared-ui-lib';

@Component({
  selector: 'fes-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  constructor(
    private breadcrumbService: DsvsBreadcrumbService
  ) {
  }

  ngOnInit(): void {
    this.initToolbar();
  }

  initToolbar(): void {
    this.breadcrumbService.setMenuItems([
      {label: 'Sprachen'}
    ]);
  }
}
