import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { DsvsPermissionService, DsvsTableColumn, DsvsToolbarItem } from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { ZapPermissions } from '../../../../../enums/zap-permissions.enum';
import { LanguageHalPage } from '../../../../shared/hal/language.hal.item';
import { LanguageServiceImpl } from '../../../../shared/services/language.service';

@Component({
  selector: 'fes-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss']
})
export class LanguageListComponent implements OnInit {
  public ZapPermissions = ZapPermissions;
  loading = false;
  languages: LanguageHalPage;
  reqTableColums: DsvsTableColumn[];
  showCreateModal = false;
  isLanguageEditable = false;
  language: any;

  @Input() exportable: boolean = false;

  constructor(
    public languageService: LanguageServiceImpl,
    private permissionService: DsvsPermissionService,
    private router: Router
  ) {}

  private _languageId: string;

  ngOnInit() {
    this.isLanguageEditable = this.permissionService.hasPermission(
      [ZapPermissions.FES_FORM_LANGUAGES_ADMIN]);
    this.reqTableColums = this.getColumns();
  }

  getToolbarItems(): DsvsToolbarItem[] {
    this.exportable = false;
    return [];
  }

  onDetail(languageHalItem: any) {
    const languageId = languageHalItem.id;
    this.router.navigate(['/administration/languages/' + languageId]);
  }

  reloadData(searchTerm: string, page?: Page) {
    this.exportable = false;
    this.loading = true;
    if (page.sort.length === 0) {
      page.sort = [{property: 'name', order: 'ASC'}];
    }

    this.languageService.search(searchTerm, page).subscribe(result => {
        this.languages = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'name', header: 'Sprache', width: '10%'},
      {field: 'active', header: 'Freigabe', sort: true, format: 'BOOL', width: '10%', centered: true},
      {field: 'displayName', header: 'Anzeigename', sort: true, width: '10%', centered: true},
    ];
  }
}

