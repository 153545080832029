import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes} from '@angular/router';
import {
  DsvsHalAsyncLoaderModule,
  DsvsInlineEditModule,
  DsvsPanelModule,
  DsvsSharedModule,
  DsvsTableModule
} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowGeneratorModule} from '@dsvs/workflow-generator-ui-lib';
import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {
  AutoCompleteModule,
  ConfirmDialogModule,
  DropdownModule,
  FileUploadModule,
  InputSwitchModule,
  ProgressSpinnerModule,
  SplitButtonModule,
  TabViewModule
} from 'primeng/primeng';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {AdministrationModule} from '../administration/administration.module';
import {ServiceupdatesModule} from '../serviceupdates/serviceupdates.module';
import {SharedModule} from '../shared/shared.module';
import { LanguageComponent } from './components/language.component';
import {LanguageListComponent} from './components/language/list/language-list.component';
import { LanguageDetailComponent } from './components/language/language-detail/language-detail.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /** PrimeNG **/
    TableModule,
    SplitButtonModule,
    MessageModule,
    ChipsModule,
    DropdownModule,
    InputSwitchModule,
    PanelModule,
    AutoCompleteModule,
    TabViewModule,
    FileUploadModule,
    ProgressSpinnerModule,
    StepsModule,
    DialogModule,
    AdministrationModule,
    WorkflowGeneratorModule,
    /** DSVS **/
    DsvsSharedModule,
    DsvsTableModule,
    DsvsPanelModule,
    ConfirmDialogModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule,
    /** FES **/
    SharedModule,
    ServiceupdatesModule
  ],
  declarations: [
    LanguageComponent,
    LanguageListComponent,
    LanguageDetailComponent,
  ],
  exports: [

  ]
})
export class LanguageModule {

  static getRoutes(): Routes {
    return [
      {path: 'list', component: LanguageListComponent},
      {path: ':id', component: LanguageDetailComponent},
      {path: '', component: LanguageComponent},
    ];
  }
}




