import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  DsvsBreadcrumbService,
  DsvsPermissionService,
  DsvsTableColumn,
  DsvsToolbarItem,
  InlineEditComponent
} from '@dsvs/dsvs-shared-ui-lib';
import { Page } from '@dsvs/hal-client';
import { WorkflowUserServiceImpl } from '@dsvs/workflow-generator-ui-lib';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { ZapPermissions } from '../../../../../enums/zap-permissions.enum';
import { SelectOptions } from '../../../../../helper/select-helper';
import { TagHalItem, TagHalPage } from '../../../../shared/hal/tag.hal.item';
import { getFilterData } from '../../../../shared/helper/FilterDataHelper';
import { TagServiceImpl } from '../../../../shared/services/tag.service';
import { TagActions } from '../tags.component';

@Component({
  selector: 'fes-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit, OnDestroy {

  loading = false;

  editTag: TagHalItem;
  categories: SelectOptions[] = [];

  tags: TagHalPage;

  reqTableColums: DsvsTableColumn[];
  toolbarSubscription: Subscription;
  categoriesSubscription: Subscription;

  showEditModal = false;

  filters: any[] = [];

  isEditable = false;

  constructor(
    private tagService: TagServiceImpl,
    private userService: WorkflowUserServiceImpl,
    private breadcrumbService: DsvsBreadcrumbService,
    private permissionService: DsvsPermissionService
  ) {
  }

  ngOnInit() {

    this.isEditable = this.permissionService.hasPermission([ZapPermissions.FES_TAG_UPDATE]);

    this.reqTableColums = this.getColumns();
    // this.toolbarSubscription = this.breadcrumbService.toolClickedHandler.subscribe(item => {
    //   this.onToolBarItemSelected(item);
    // });
    this.categoriesSubscription = this.tagService.categories().subscribe(res => this.categories = res.map(tagcategory => {
      return <SelectOptions>{value: tagcategory.id, label: tagcategory.title};
    }));
  }

  ngOnDestroy(): void {
    if (this.toolbarSubscription) {
      this.toolbarSubscription.unsubscribe();
    }
    this.categoriesSubscription.unsubscribe();
  }

  fetchColorsForTags(tag) {
    return this.tagService.getColorForTagId(tag);
    // return getColorForTag(tag);
  }

  onToolBarItemSelected(item: DsvsToolbarItem) {
    switch (item.id) {
      case TagActions.CREATE_TAG: {
        this.onCreate();
        break;
      }
    }
  }

  private getColumns(): DsvsTableColumn[] {
    return [
      {field: 'name', header: 'Name', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'description', header: 'Beschreibung', sort: true, filter: '', hidden: false, format: 'EDITABLE'},
      {field: 'deleted', header: 'Status', sort: true, filter: '', hidden: false, format: 'DELETED'},
      {field: 'category', header: '', format: 'COLOR', width: '12px'},
      {
        field: 'category', header: 'Kategorie', sort: true, filter: '', hidden: false, format: 'EDITABLE_SELECT', filterable: true,
        filterDataService: () => this.tagService.categories()
      },
      {
        field: 'createdByName', header: 'Ersteller', sort: true, filter: '', hidden: false, filterable: true,
        filterDataService: () => this.userService.getAll()
      },
      {field: 'lastModifiedDate', header: 'Datum', sort: true, filter: '', hidden: false, format: 'DATE'},
      {field: '', header: 'Aktionen', width: '180px', format: 'ACTION'}
    ];
  }

  getSplitButtonItems(tag: TagHalItem): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        label: 'Bearbeiten', icon: 'fa ui-icon-edit', command: () => {
          this.onDetail(tag);
        }
      }
    ];
    if (tag.data.deleted) {
      menuItems.push({
        label: 'Aktivieren',
        icon: 'fa ui-icon-check-circle',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_TAG_ACTIVATE]),
        command: () => {
          this.updateTagDeleted(tag, false);
        }
      });
    } else {
      menuItems.push({
        label: 'Deaktivieren',
        icon: 'fa ui-icon-remove-circle',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_TAG_ACTIVATE]),
        command: () => {
          this.updateTagDeleted(tag, true);
        }
      });
    }
    return menuItems;
  }

  onDetail(tagHalItem: TagHalItem) {
    this.editTag = tagHalItem;
    this.showEditModal = true;
  }

  onCreate() {
    this.editTag = null;
    this.showEditModal = true;
  }

  reloadData(searchTerm: string, page?: Page) {
    this.loading = true;
    this.tagService.search(searchTerm, page).subscribe(result => {
        this.tags = result;
        this.loading = false;
      },
      err => {
        this.loading = false;
        console.error(err);
      });
  }

  filterData(searchTerm: string, data?: { pageRequest: Page, value: any }) {

    this.filters = getFilterData(data.value, this.filters);

    if (this.filters.length > 0) {
      this.tagService.filter(searchTerm, data.pageRequest, JSON.stringify(this.filters)).subscribe(result => {
          this.tags = result;
          this.loading = false;
        },
        err => {
          this.loading = false;
          console.error(err);
        });
    } else {
      this.tagService.search(searchTerm, data.pageRequest).subscribe(result => {
          this.tags = result;
          this.loading = false;
        },
        err => {
          this.loading = false;
          console.error(err);
        });
    }
  }

  saveRowCategory = (value, component: InlineEditComponent): Observable<TagHalItem> => {
    component.model.data.category = value.value;
    return component.model.save();
  };

  saveRow = (value, component: InlineEditComponent): Observable<TagHalItem> => {
    return component.model.save();
  };

  updateTagDeleted(tagHalItem: TagHalItem, deleted) {
    const tag = tagHalItem.data;
    tag.deleted = deleted;
    this.tagService.update(tag).subscribe(
      result => {
        this.reloadData('');
      },
      err => {
        console.error(err);
      });
  }

  receiveShowEditModal($event) {
    this.showEditModal = $event;
    this.editTag = null;
    this.reloadData('');
  }

  getToolbarItems(): DsvsToolbarItem[] {
    return [
      {
        id: TagActions.CREATE_TAG,
        icon: 'add_location',
        tooltip: 'Neuer Tag',
        disabled: !this.permissionService.hasPermission([ZapPermissions.FES_TAG_CREATE]),
        callback: () => {
          this.onCreate();
        }
      }
    ];
  }

}
