import { HalClient, HalClientFactory, HalItem, HalItemImpl, HalPageImpl, HalRelationItem, HalRelationPage } from '@dsvs/hal-client';
import { Language } from '../interfaces/dtos/language';

export class LanguageHalItem extends HalItemImpl<Language> {

  constructor(
    data: Language,
    client: HalClient,
    factory: HalClientFactory
  ) {
    super(data, client, factory);
  }
}

export class LanguageHalPage extends HalPageImpl<Language, LanguageHalItem> {

}

export class LanguageRelationPage extends HalRelationPage<Language, LanguageHalItem, LanguageHalPage> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, LanguageHalPage, LanguageHalItem);
  }
}

export class LanguageRelationItem extends HalRelationItem<Language, LanguageHalItem> {
  constructor(
    relation: string,
    item: HalItem<any>
  ) {
    super(relation, item, LanguageHalItem);
  }
}
