import { Component, OnInit } from '@angular/core';
import {FesWorkflowActionComponent} from "../fes-workflow-action";
import {WorkflowActionDataDto} from "@dsvs/workflow-generator-ui-lib";
import {MessageService} from "primeng/primeng";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActionContextHalItem} from "@dsvs/workflow-generator-dto";

@Component({
  selector: 'fes-fes-workflow-action-weitere-referenz-add',
  templateUrl: './fes-workflow-action-weitere-referenz-add.component.html',
  styleUrls: ['./fes-workflow-action-weitere-referenz-add.component.scss']
})
export class FesWorkflowActionWeitereReferenzAddComponent extends FesWorkflowActionComponent implements OnInit {

  constructor(messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {
    this.inputForm = new FormGroup({
      wreferenz: new FormControl('', Validators.required),
    })

    this.loadProcess();
  }

  loadProcess() {
    this.context.process.async.subscribe(
      processHalItem => {
        this.inputForm.patchValue({wreferenz: processHalItem.data.wreferenz});
      });
  }

  collectActionData(): WorkflowActionDataDto {
    return <WorkflowActionDataDto>{
      wreferenz: this.inputForm.controls.wreferenz.value,
    };
  }

  onSuccess(actionContext: ActionContextHalItem) {
    super.onSuccess(actionContext);
    this.context.process.reload();
    this.loadProcess();
  }

  resetForm(){
  }

  add(): void {
    this.saveAction();
  }

}
