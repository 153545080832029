import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Routes} from '@angular/router';
import {
  DsvsHalAsyncLoaderModule,
  DsvsInlineEditModule,
  DsvsPanelModule,
  DsvsSharedModule,
  DsvsTableModule
} from '@dsvs/dsvs-shared-ui-lib';
import {WorkflowGeneratorModule} from '@dsvs/workflow-generator-ui-lib';
import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {
  AutoCompleteModule,
  ConfirmDialogModule,
  DropdownModule,
  FileUploadModule,
  InputSwitchModule,
  ProgressSpinnerModule, RadioButtonModule,
  SplitButtonModule,
  TabViewModule
} from 'primeng/primeng';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {AdministrationModule} from '../administration/administration.module';
import {ServiceupdatesModule} from '../serviceupdates/serviceupdates.module';
import {SharedModule} from '../shared/shared.module';
import {DevVersionCancelModalComponent} from './components/dev-version/dev-version-cancel-modal/dev-version-cancel-modal.component';
import {DevVersionDownloadModalComponent} from './components/dev-version/dev-version-download-modal/dev-version-download-modal.component';
import {DevVersionTableComponent} from './components/dev-version/dev-version-table/dev-version-table.component';
import {FormularComponent} from './components/formular.component';
import {FieldNotPresentErrorComponent} from './components/formular/create/field-not-present-error/field-not-present-error.component';
import {AddDevVersionFormularComponent} from './components/formular/create/forms/add-dev-version-formular/add-dev-version-formular.component';
import {AddFormFormularComponent} from './components/formular/create/forms/add-form-formular/add-form-formular.component';
import {AddOtherVersionFormularComponent} from './components/formular/create/forms/add-other-version-formular/add-other-version-formular.component';
import {AddVersionFormularComponent} from './components/formular/create/forms/add-version-formular/add-version-formular.component';
import {AddXdpFormularComponent} from './components/formular/create/forms/add-xdp-formular/add-xdp-formular.component';
import {ExternRecordComponent} from './components/formular/extern-record/extern-record.component';
import {FormularCreateModalComponent} from './components/formular/create/formular-create-modal/formular-create-modal.component';
import {FormularCreateScreenComponent} from './components/formular/create/formular-create-screen.component';
import {DevversionCreateModalComponent} from './components/formular/create/modals/devversion-create-modal/devversion-create-modal.component';
import {UnknownCreateModalComponent} from './components/formular/create/modals/unknown-create-modal/unknown-create-modal.component';
import {VersionCreateModalComponent} from './components/formular/create/modals/version-create-modal/version-create-modal.component';
import {UploadFormularComponent} from './components/formular/create/upload-formular/upload-formular.component';
import {FormularDeleteComponent} from './components/formular/delete/formular-delete.component';
import {FormularScreenComponent} from './components/formular/formular-screen.component';
import {FormularListWorkspaceComponent} from './components/formular/list-workspace/formular-list-workspace.component';
import {FormularListComponent} from './components/formular/list/formular-list.component';
import {FormularSearchScreenComponent} from './components/formular/search-screen/formular-search-screen.component';
import {FormularSearchComponent} from './components/formular/search/formular-search.component';
import {VersionDetailComponent} from './components/version/detail/version-detail.component';
import {ReleasedVersionsTableComponent} from './components/version/released-versions-table/released-versions-table.component';
import {VersionTableComponent} from './components/version/table/version-table.component';
import {ExternRecordTableComponent} from './components/formular/extern-record/extern-record-table/extern-record-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    /** PrimeNG **/
    TableModule,
    SplitButtonModule,
    MessageModule,
    ChipsModule,
    DropdownModule,
    InputSwitchModule,
    PanelModule,
    AutoCompleteModule,
    TabViewModule,
    FileUploadModule,
    ProgressSpinnerModule,
    StepsModule,
    DialogModule,
    AdministrationModule,
    WorkflowGeneratorModule,
    /** DSVS **/
    DsvsSharedModule,
    DsvsTableModule,
    DsvsPanelModule,
    ConfirmDialogModule,
    DsvsInlineEditModule,
    DsvsHalAsyncLoaderModule,
    /** FES **/
    SharedModule,
    ServiceupdatesModule,
    RadioButtonModule
  ],
  declarations: [
    FormularScreenComponent,
    VersionTableComponent,
    FormularComponent,
    AddFormFormularComponent,
    AddVersionFormularComponent,
    AddDevVersionFormularComponent,
    AddXdpFormularComponent,
    ExternRecordComponent,
    ExternRecordTableComponent,
    FieldNotPresentErrorComponent,
    FormularCreateScreenComponent,
    FormularSearchScreenComponent,
    FormularSearchComponent,
    VersionDetailComponent,
    UploadFormularComponent,
    DevVersionDownloadModalComponent,
    DevVersionTableComponent,
    DevVersionCancelModalComponent,
    FormularListComponent,
    FormularListWorkspaceComponent,
    FormularDeleteComponent,
    ReleasedVersionsTableComponent,
    AddOtherVersionFormularComponent,
    UnknownCreateModalComponent,
    VersionCreateModalComponent,
    DevversionCreateModalComponent,
    FormularCreateModalComponent,
    ExternRecordTableComponent
  ],
  exports: [
    FormularSearchComponent,
    FormularCreateModalComponent
  ]
})
export class FormularModule {

  static getRoutes(): Routes {
    return [
      {path: 'list', component: FormularListWorkspaceComponent},
      {path: 'search', component: FormularSearchScreenComponent},
      {path: 'create', component: FormularCreateScreenComponent},
      {path: ':id', component: FormularSearchScreenComponent},
      {path: '', component: FormularSearchScreenComponent}
    ];
  }
}




