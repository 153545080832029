import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {DsvsSearchEvent} from '@dsvs/dsvs-shared-ui-lib';
import {HalCrudServiceImpl} from '../../hal/hal.crud.service';
import {HalRelationPage} from '@dsvs/hal-client';


@Component({
  selector: 'fes-autocomplete-dialog',
  templateUrl: './fes-autocomplete-dialog.component.html',
  styleUrls: ['./fes-autocomplete-dialog.component.scss']
})
export class FesAutocompleteDialogComponent implements OnInit, OnDestroy {
  // value
  @Input() value: any;
  // model
  @Input() model: any;
  // delegate save method
  @Input() onSave: (value: any, component: any) => Observable<any>;
  // delegate fetch method
  @Input() onFetch: (value: any, component: any) => Observable<any>;
  // disable input
  @Input() disabled: boolean;
  // callback for color of value
  @Input() onColorForValue: (value: any, component: any) => string;
  // service for fetching data
  @Input() fetchService: HalCrudServiceImpl<any, any, any>;
  // hal relation page for fetching data
  @Input() fetchServiceHalRelationPage: HalRelationPage<any, any, any>;
  // displayValue
  @Input() displayValue: any;
  // isMultipleSelect
  @Input() isMultipleSelect = true;
  // label
  @Input() label: string;
  // emptyText
  @Input() emptyText: string;
  // view for search
  @Input() view: any;
  // use plus x more badge
  @Input() usePlusMore = false;
  @Input() plusMoreMaxValue = 2;
  // use user circle view
  @Input() useUserBadges = false;
  // value change emitter
  @Output() valueChange = new EventEmitter();
  @Input()
  selectValues: any = [];
  @Input() forceHAL = true;
  @Input() useFlatArray = false;

  pagedResults: any = [];

  pageRequest: DsvsSearchEvent = {
    number: 0,
    size: 10,
    searchTerm: '',
    sort: [],
    totalElements: 0
  };

  constructor() {

  }

  ngOnInit(): void {
    this.initAutocompleteData();
  }

  initAutocompleteData() {
    if (this.fetchServiceHalRelationPage || this.fetchService) {
      this.getPagedResults(this.pageRequest);
    } else {
      this.pagedResults = this.selectValues;
    }
  }

  ngOnDestroy(): void {
  }

  getPagedResults(event: DsvsSearchEvent) {
    if (event.searchTerm) {
      this.pageRequest.searchTerm = event.searchTerm;
    } else {
      this.pageRequest.searchTerm = '';
    }

    if (event.number === 0) {
      this.pagedResults = [];
    }

    this.pageRequest.number = event.number;

    const service = this.fetchServiceHalRelationPage ?
      this.fetchServiceHalRelationPage.async :
      this.fetchService.search(this.pageRequest.searchTerm, this.pageRequest, this.view);

    // TODO: setting params causes crashes sometimes
    // const service = this.fetchServiceHalRelationPage ?
    //   this.fetchServiceHalRelationPage.setParams({
    //     searchTerm: this.pageRequest.searchTerm,
    //     size: this.pageRequest.size,
    //     number: this.pageRequest.number,
    //     totalElements: this.pageRequest.totalElements
    //   }).async :
    //   this.fetchService.search(this.pageRequest.searchTerm, this.pageRequest, this.view);

    if (service) {
      service.subscribe(result => {
        for (let i = 0; i < result.content.length; i++) {
          this.pagedResults.push(result.content[i]);
        }

        this.pageRequest = {
          number: result.page.number,
          size: result.page.size,
          sort: result.page.sort,
          totalElements: result.page.totalElements,
          searchTerm: this.pageRequest.searchTerm
        };
      }, error => {
        console.error(error);
      });
    }
  }

  valueHasChanged($event) {
    if (this.valueChange) {
      this.valueChange.emit($event);
    }
  }
}
