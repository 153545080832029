import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  DsvsAuthorizationGuard,
  DsvsLandingPageComponent,
  DsvsLandingPageModule,
  DsvsLogoutComponent,
  DsvsNotificationModule,
  DsvsNotificationViewComponent,
  DsvsSettingsComponent,
  DsvsSettingsDeactivateGuard,
  DsvsSettingsResolver,
  DsvsStartComponent,
  DsvsUnderConstructionComponent,
  DsvsUnderConstructionModule,
  DsvsUserInfoComponent,
  DsvsUserInfoModule
} from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowGeneratorMockServicesModule, WorkflowGeneratorModule } from '@dsvs/workflow-generator-ui-lib';
import { AdministrationActivateGuard } from './guards/administration.guard';
import { FIReleaseDateActivateGuard } from './guards/fireleasedate.guard';
import { FormActivateGuard } from './guards/form.guard';
import { LanguageActivateGuard } from './guards/language.guard';
import { ProcessActivateGuard } from './guards/process.guard';
import { RequirementActivateGuard } from './guards/requirement.guard';
import { ScanpoolActivateGuard } from './guards/scanpool.guard';
import { SearchActivateGuard } from './guards/search.guard';
import { ServiceupdateActivateGuard } from './guards/serviceupdate.guard';
import { AdministrationModule } from './modules/administration/administration.module';
import { WorkspacesComponent } from './modules/administration/components/workspaces/workspaces.component';
import { FiReleaseDatesSearchComponent } from './modules/fi-release-dates/components/fi-release-dates-search/fi-release-dates-search.component';

import { FormularComponent } from './modules/formular/components/formular.component';
import { FormularModule } from './modules/formular/formular.module';
import { LanguageComponent } from './modules/language/components/language.component';
import { LanguageModule } from './modules/language/language.module';
import { ProcessListComponent } from './modules/process/components/process-list/process-list.component';
import { ProcessModule } from './modules/process/process.module';
import { RequirementsSearchComponent } from './modules/requirements/components/search/requirements-search.component';
import { ScanpoolSearchComponent } from './modules/scanpool/components/search/scanpool-search.component';
import { SearchComponent } from './modules/search/components/search.component';
import { SearchModule } from './modules/search/search.module';
import { ServiceupdateSearchComponent } from './modules/serviceupdates/components/search/serviceupdate-search.component';

const appRoutes: Routes = [
    {
      path: '', component: DsvsStartComponent, resolve: {options: DsvsSettingsResolver},
      children: [
        {path: 'workflowgenerator', children: WorkflowGeneratorModule.getRoutes()},
        {path: 'processes', children: ProcessModule.getRoutes(), canActivate: [ProcessActivateGuard]},
        {path: 'home', component: ProcessListComponent}, // TODO: check for guard
        {
          path: 'settings',
          component: DsvsSettingsComponent,
          canActivate: [DsvsAuthorizationGuard],
          canDeactivate: [DsvsSettingsDeactivateGuard]
        },
        {path: 'notifications', component: DsvsNotificationViewComponent, canActivate: [DsvsAuthorizationGuard]},
        {
          path: 'forms',
          component: FormularComponent,
          canActivate: [DsvsAuthorizationGuard, FormActivateGuard],
          children: FormularModule.getRoutes()
        },
        {path: 'notifications/:id', component: DsvsNotificationViewComponent, canActivate: [DsvsAuthorizationGuard]},
        {path: 'userinfo', component: DsvsUserInfoComponent, canActivate: [DsvsAuthorizationGuard]},
        {path: 'uc', component: DsvsUnderConstructionComponent, canActivate: [DsvsAuthorizationGuard]},
        {
          path: 'requirements',
          component: RequirementsSearchComponent,
          canActivate: [DsvsAuthorizationGuard, RequirementActivateGuard]
        },
        {
          path: 'serviceupdates',
          component: ServiceupdateSearchComponent,
          canActivate: [DsvsAuthorizationGuard, ServiceupdateActivateGuard]
        },
        {
          path: 'scanpools',
          component: ScanpoolSearchComponent,
          canActivate: [DsvsAuthorizationGuard, ScanpoolActivateGuard]
        },
        {
          path: 'fireleasedates',
          component: FiReleaseDatesSearchComponent,
          canActivate: [DsvsAuthorizationGuard, FIReleaseDateActivateGuard]
        },
        {
          path: 'administration',
          component: WorkspacesComponent,
          canActivate: [DsvsAuthorizationGuard, AdministrationActivateGuard],
          children: AdministrationModule.getRoutes()
        },
        {
          path: 'search',
          component: SearchComponent,
          canActivate: [DsvsAuthorizationGuard, SearchActivateGuard],
          children: SearchModule.getRoutes()
        },
        {path: '', redirectTo: 'home', pathMatch: 'full'}
      ]
    },
    {
      path: 'logout', component:
      DsvsLogoutComponent
    },
    {
      path: 'landing', component:
      DsvsLandingPageComponent, pathMatch:
        'full'
    },
    {path: 'landing/:mode', component: DsvsLandingPageComponent, pathMatch: 'full'}

  ]
;

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {enableTracing: false}),
    WorkflowGeneratorModule,
    DsvsNotificationModule,
    DsvsUnderConstructionModule,
    DsvsLandingPageModule,
    WorkflowGeneratorMockServicesModule,
    DsvsUserInfoModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
