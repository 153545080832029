import { Component } from '@angular/core';
import {
  DsvsAuthorizationService,
  DsvsBaseLayoutService,
  DsvsLayoutStateService,
  DsvsMenuService,
  DsvsNotificationService,
  DsvsPermissionService,
  DsvsSharedUiLibConfigService
} from '@dsvs/dsvs-shared-ui-lib';
import { WorkflowGeneratorConfigurationService, WorkflowPermissions } from '@dsvs/workflow-generator-ui-lib';
import { MenuItem } from 'primeng/api';
import { environment } from '../environments/environment';
import { WorkspaceSelectComponent } from './components/workspace-select/workspace-select.component';
import { ZapPermissions } from './enums/zap-permissions.enum';
import { FesWorkflowTypeMappingService } from './modules/fes-workflow/services/fes-workflow-type-mapping.service';
import { FesWorkflowActionService } from './modules/fes-workflow/services/fes-workflow.service';

@Component({
  selector: 'fes-root',
  template: `
    <router-outlet></router-outlet>`,
  styleUrls: ['../assets/dsvs-theme.scss']
})
export class AppComponent {
  title = 'fes';

  private loginDone = false;
  private layoutDone = false;

  constructor(private dsvsConfigService: DsvsSharedUiLibConfigService,
              private menuService: DsvsMenuService,
              private authService: DsvsAuthorizationService,
              private workflowConfigService: WorkflowGeneratorConfigurationService,
              private layoutStateService: DsvsLayoutStateService,
              private fesActionService: FesWorkflowActionService,
              private permissionService: DsvsPermissionService,
              private fesTypeMappingService: FesWorkflowTypeMappingService,
              private layoutBaseService: DsvsBaseLayoutService,
              private notificationService: DsvsNotificationService) {

    this.configureSharedLibrary();
    this.configureLayoutState();
    this.configureBaseLayout();

    this.configureWorkflowGeneratorLibrary();
    this.configureWorkflowModule();

    this.configureMenu();

    this.authService.getLoginDone().subscribe(login => {
      this.loginDone = login;
      if (this.loginDone) {
        this.onLoginDone();
      }

    });

    if (this.authService.checkLogin()) {
      this.loginDone = true;
      this.onLoginDone();
    }

  }

  private onLoginDone() {

    this.checkToExtend();
    setTimeout(() => this.configureNotifications(), 500);
  }

  private configureNotifications() {
    /** Falls die Notifications noch nicht abgeholt wurden, dann jetzt die Initialisierung durchführen */
    if (!this.notificationService.isInitialized) {
      this.notificationService.initializeUserNotifications(this.authService.getJWT());
    }
  }

  private configureSharedLibrary() {
    this.dsvsConfigService.config.production = environment.production;

    this.dsvsConfigService.config.communication.scope = environment.scope;
    this.dsvsConfigService.config.communication.zapurl = environment.zapurl;
    this.dsvsConfigService.config.communication.apourl = environment.apourl;
    this.dsvsConfigService.config.communication.backend = environment.backend;
    this.dsvsConfigService.config.communication.zapinit = environment.zapinit;
    this.dsvsConfigService.config.communication.loggerSystemId = environment.loggerSystemId;

    this.dsvsConfigService.config.layout.topbar.logoUrl = 'assets/fes/images/anwendungslogo_ffb_fes.svg';
    this.dsvsConfigService.config.layout.appLogo = 'assets/fes/images/logo_fes.svg';
    this.dsvsConfigService.config.layout.appName = 'FES';
  }

  private configureLayoutState() {
    this.layoutStateService.onToggleMenuClick(null);
  }

  private configureBaseLayout() {

    this.layoutBaseService.onLayoutInitFinished.subscribe(service => {
      this.layoutDone = true;
      this.checkToExtend();
    });
  }

  private configureWorkflowGeneratorLibrary() {
    // this.workflowConfigService.config.ui_url = '/admin/wfg';

    this.workflowConfigService.externalPermissions = (permission: WorkflowPermissions) => {

      switch (permission) {
        // Category
        case WorkflowPermissions.CATEGORY_CREATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CATEGORIE_CREATE]);
        case WorkflowPermissions.CATEGORY_READ:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CATEGORIE_READ]);
        case WorkflowPermissions.CATEGORY_UPDATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CATEGORIE_UPDATE]);
        case WorkflowPermissions.CATEGORY_DELETE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CATEGORIE_DELETE]);
        // Crew
        case WorkflowPermissions.CREW_CREATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CREW_CREATE]);
        case WorkflowPermissions.CREW_READ:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CREW_READ]);
        case WorkflowPermissions.CREW_UPDATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CREW_UPDATE]);
        case WorkflowPermissions.CREW_DELETE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_CREW_DELETE]);
        // Crew
        case WorkflowPermissions.GROUP_CREATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_CREATE]);
        case WorkflowPermissions.GROUP_READ:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_READ]);
        case WorkflowPermissions.GROUP_UPDATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_UPDATE]);
        case WorkflowPermissions.GROUP_DELETE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_GROUP_DELETE]);
        // Role
        case WorkflowPermissions.ROLE_CREATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_ROLE_CREATE]);
        case WorkflowPermissions.ROLE_READ:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_ROLE_READ]);
        case WorkflowPermissions.ROLE_UPDATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_ROLE_UPDATE]);
        case WorkflowPermissions.ROLE_DELETE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_ROLE_DELETE]);
        // Workflow
        case WorkflowPermissions.WORKFLOW_CREATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_WORKFLOW_CREATE]);
        case WorkflowPermissions.WORKFLOW_READ:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_WORKFLOW_READ]);
        case WorkflowPermissions.WORKFLOW_UPDATE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_WORKFLOW_UPDATE]);
        case WorkflowPermissions.WORKFLOW_DELETE:
          return this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_WORKFLOW_DELETE]);
      }

    };
  }

  private configureWorkflowModule() {
    // this.workflowActionExtensionService.clear();

    /**  */
    this.fesActionService.registerActions();

    /** Workflow Type Mapping registration */
    this.fesTypeMappingService.registerTypes();

  }

  private configureMenu() {

    /** Menu **/
    this.menuService.requestMenu.subscribe(val => {

      this.menuService.clearMenu();

      // Auftrag
      if (this.permissionService.hasPermission([ZapPermissions.FES_PROCESS_OVERVIEW_READ])) {
        this.menuService.addMenuItem({label: 'Auftragsbuch', icon: 'library_books', routerLink: ['/processes']});
      }

      // Anforderungen
      if (this.permissionService.hasPermission([ZapPermissions.FES_REQUIREMENT_OVERVIEW_READ])) {
        this.menuService.addMenuItemRequirements();
      }

      // Scanpool
      if (this.permissionService.hasPermission([ZapPermissions.FES_SCANPOOL_OVERVIEW_READ])) {
        this.menuService.addMenuItem({label: 'Scanpool', icon: 'collections_bookmark', routerLink: ['/scanpools']});
      }

      // Serviceupdate
      if (this.permissionService.hasPermission([ZapPermissions.FES_SERVICEUPDATE_OVERVIEW_READ])) {
        this.menuService.addMenuItemServiceupdates();
      }

      // FI Termine
      if (this.permissionService.hasPermission([ZapPermissions.FES_FIRELEASEDATE_OVERVIEW_READ])) {
        this.menuService.addMenuItem({label: 'FI-Termine', icon: 'date_range', routerLink: ['/fireleasedates']});
      }

      // Formulare
      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_OVERVIEW_READ])) {
        this.menuService.addMenuItem({label: 'Formulare', icon: 'assignment', routerLink: ['/forms/list']});
      }
      // Search
      if (this.permissionService.hasPermission([ZapPermissions.FES_SEARCH_OVERVIEW_READ])) {
        this.menuService.addMenuItemSearch();
      }

      // Administration (Verwaltung)
      const subItems: MenuItem[] = [];
      if (this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_OVERVIEW_READ])) {
        subItems.push({label: 'Workspaces', icon: 'folder_open', routerLink: ['/administration/workspaces']});
      }
      if (this.permissionService.hasPermission([ZapPermissions.FES_WORKSPACE_OVERVIEW_READ])) {
        subItems.push({label: 'Globale Crews', icon: 'group', routerLink: ['/administration/globalcrews']});
      }
      // Sprachen
      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_LANGUAGES_ADMIN])) {
        subItems.push({label: 'Spracheneinstellung', icon: 'g_translate', routerLink: ['/administration/languages']});
      }
      if (this.permissionService.hasPermission([ZapPermissions.FES_TAG_OVERVIEW_READ])) {
        subItems.push({label: 'Tags', icon: 'location_on', routerLink: ['/administration/tags']});
      }
      if (this.permissionService.hasPermission([ZapPermissions.PERMISSION_NOT_DEFINED])) {
        subItems.push({label: 'Auftragssammlungen', icon: 'folder', routerLink: ['/administration/projects']});
      }
      if (this.permissionService.hasPermission([ZapPermissions.FES_USER_OVERVIEW_READ])) {
        subItems.push({label: 'User', icon: 'person', routerLink: ['/administration/users']});
      }
      if (this.permissionService.hasPermission([ZapPermissions.FES_FORM_ADMIN])) {
        subItems.push({label: 'Formulare', icon: 'assignment', routerLink: ['/administration/forms']});
      }

      if (subItems.length > 0) {
        this.menuService.addMenuItem({label: 'Verwaltung', icon: 'business_center', items: subItems});
      }

      // Workflow
      if (this.permissionService.hasPermission([ZapPermissions.FES_WORKFLOW_OVERVIEW_READ])) {
        const menu: MenuItem = this.workflowConfigService.getMenu();
        if (menu.items && menu.items.length > 0) {
          this.menuService.addMenuItem(menu);
        }
      }

      this.menuService.addUserMenu();
    });

  }

  private checkToExtend() {
    if (this.loginDone && this.layoutDone) {
      setTimeout(() => this.layoutBaseService.extendMenu(WorkspaceSelectComponent), 500); // 2500 is millisecond
    }
  }
}
